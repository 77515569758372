<template>
    <v-container fluid>

        <v-data-table :headers="headers" :items="items" :items-per-page="10" item-key="id" sort-by="name"
            mobile-breakpoint="0" calculate-widths class="elevation-4" :loading="loading_status" loader-height="10"
            loading-text="Cargando ...">
            <template #top>
                <v-toolbar rounded flat>
                    <h3>Ventas por vendedor</h3>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-row class="pa-2">
                    <v-col cols="12" md="3">
                        <v-text-field v-model="dateFrom" label="Desde" hide-details="auto" required dense outlined
                            type="date" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field v-model="dateTo" label="Hasta" hide-details="auto" required dense outlined
                            type="date" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn color="success" @click="get_report">Buscar</v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.valor`]="{ item }">
                <span>{{ "$ " + parseFloat(item.valor).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.descuento`]="{ item }">
                <span>{{ "$ " + parseFloat(item.descuento).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
                <span>{{ parseFloat(item.quantity).toLocaleString(2) }}</span>
            </template>
            <template slot="body.append">
                <tr>
                    <th class="title">Total</th>
                    <th class="title"></th>
                    <th class="text-center ">{{ "$ " + sumTable('items', 'descuento') }}</th>
                    <th class="text-right ">{{ "$ " + sumTable('items', 'valor') }}</th>
                    <th class="text-right ">{{ sumTable('items', 'quantity') }}</th>
                </tr>
            </template>
        </v-data-table>

        <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />

    </v-container>
</template>

<script>
import { webserver, getToday, getdays_ago } from "../services/webserver.js";
import { GChart } from "vue-google-charts";

import createDoc from "../utils/create_doc.js";
export default {
    components: { GChart },
    data() {
        return {
            loading_status: false,
            headers: [
                {
                    text: "Código",
                    align: "start",
                    sortable: true,
                    value: "codigo",
                    dataType: "text",
                },
                {
                    text: "Nombre",
                    align: "start",
                    sortable: true,
                    value: "nombre",
                    dataType: "text",
                },
                {
                    text: "Descuento",
                    align: "center",
                    sortable: true,
                    value: "descuento",
                    dataType: "number",
                },
                {
                    text: "Valor",
                    align: "end",
                    sortable: true,
                    value: "valor",
                    dataType: "number",
                },
                {
                    text: "Cantidad",
                    align: "end",
                    sortable: true,
                    value: "quantity",
                    dataType: "number",
                },
                {
                    text: "Días",
                    align: "end",
                    sortable: true,
                    value: "days",
                    dataType: "number",
                },
                {
                    text: "Promedio pares día",
                    align: "end",
                    sortable: true,
                    value: "promedio",
                    dataType: "number",
                },
                {
                    text: "Rating",
                    align: "end",
                    sortable: true,
                    value: "rating",
                    dataType: "number",
                },
                {
                    text: "Qty %",
                    align: "end",
                    sortable: true,
                    value: "percent",
                    dataType: "number",
                },
            ],
            items: [],
            dialog: false,
            item: createDoc(),
            dateFrom: getdays_ago(-30),
            dateTo: getToday(),
            chartData: null,
            chartOptions: {
                curveType: "function",
                legend: { position: "right" },
                interpolateNulls: true,
                crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
                title: "",
                isStacked: false,
                hAxis: {
                    title: "",
                    slantedText: true,
                    slantedTextAngle: 15,
                },
                colors: ['#1b9e77', '#d95f02', '#7570b3'],
                height: 400,
            },
        };
    },
    mounted() {
        this.get_report();
    },
    methods: {
        sumTable(table, key) {
            // sum data in give key (property)
            return parseFloat(this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
        },

        get_report() {
            this.loading_status = true;
            var qry = {
                store: window.store.store_id,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
            };

            webserver("vendors_stat", qry, (data) => {
                this.items = data;
                var result = [];
                data.reduce(function (res, value) {
                    if (!res[value.nombre]) {
                        res[value.nombre] = { label: value.nombre, value: 0 };
                        result.push(res[value.nombre]);
                    }
                    res[value.nombre].value += parseFloat(value.quantity);
                    return res;
                }, {});

                result.sort((a, b) =>
                    a.value > b.value ? 1 : b.value > a.value ? -1 : 0
                );
                var dtSet = [];
                dtSet.push(["Tipo", "Cantidad"]);
                result.forEach((rg) => {
                    var item = [];
                    item.push(rg.label);
                    item.push(rg.value);
                    dtSet.push(item);
                });
                this.chartData = dtSet;
                this.loading_status = false;
            });
        },
    },
};
</script>

<style>
</style>
